import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmResetPassword } from 'aws-amplify/auth';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const ResetPasswordVerificationPage = ({ location }) => {
    const navigate = useNavigate();
    const [verificationCode, setVerificationCode] = useState('');
    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        // Assuming you have a function to verify the code and reset the password
        try {
            
            await confirmResetPassword({
                username,
                "confirmationCode": verificationCode,
                newPassword,
            });
            setMessage('Your password has been reset successfully.');
            // Optionally, redirect the user to the login page
        } catch (error) {
            console.error(error);
            if (error.message === "Invalid verification code provided, please try again.") {
                setError('Invalid verification code provided, please try again.');
            } else if (error.message === "Unrecognizable lambda output") {
                navigate('/login')
            } else {
                setError('Failed to reset password. Please try again.');
            }
        }
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="verificationCode">
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        {error && <p className="text-danger">{error}</p>}
                        {message && <p className="text-success">{message}</p>}
                        <Button variant="primary" type="submit">
                            Reset Password
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPasswordVerificationPage;