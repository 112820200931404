import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from 'aws-amplify/auth';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function ForgotPasswordPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');


    // async function handleResetPassword(username) {
    //     try {
    //       const output = await resetPassword({ username });
    //       handleResetPasswordNextSteps(output);
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
      
    //   function handleResetPasswordNextSteps(output) {
    //     const { nextStep } = output;
    //     switch (nextStep.resetPasswordStep) {
    //       case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
    //         const codeDeliveryDetails = nextStep.codeDeliveryDetails;
    //         console.log(
    //           `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
    //         );
    //         // Collect the confirmation code from the user and pass to confirmResetPassword.
    //         break;
    //       case 'DONE':
    //         console.log('Successfully reset password.');
    //         break;
    //     }
    //   }

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');
    
        const currentUsername = emailInputRef.current.value.trim(); // Trim to remove any leading/trailing whitespace
    
        if (!currentUsername) {
            setError('Email address is required.');
            return; // Exit the function if the username is empty
        }
    
        try {
            await resetPassword({ "username": currentUsername });
            setMessage('A password reset link has been sent to your email address.');
            navigate('/forgotPasswordVerification', { state: { username: currentUsername } });
        } catch (error) {
            console.error('Error resetting password:', error);
            console.error('Error code:', error.message);
            if (error.message === "Username/client id combination not found.") {
                setError('Username not found. Please check your details and try again.');
            } else {
                setError('Failed to reset password. Please try again.');
            }
        }
    };

    const emailInputRef = useRef(null);

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    <h2>Reset Password</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}
                    <Form onSubmit={handleResetPassword}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                ref={emailInputRef}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Send Reset Link
                        </Button>
                    </Form>
                    <div className="mt-3">
                        <Button variant="link" onClick={() => navigate('/login')}>
                            Back to Login
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ForgotPasswordPage;