import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './EditableCell.css'; // Ensure this CSS includes the new styles for hover effect

export const EditableCheckboxCell = ({ shortlink, isChecked, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedChecked, setEditedChecked] = useState(isChecked);

    const handleEditClick = () => setIsEditing(true);

    const handleSaveClick = () => {
        onSave(shortlink.id, editedChecked);
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedChecked(isChecked);
    };

    const handleCheckboxClick = (e) => {
        // Prevent changing the checkbox state
        e.preventDefault();
    };
    
    return (
        <td className="editable-cell">
            {isEditing ? (
                <div className="edit-mode">
                    <input 
                        type="checkbox" 
                        checked={editedChecked} 
                        onChange={(e) => setEditedChecked(e.target.checked)}
                    />
                    <Button variant="primary" size="sm" onClick={handleSaveClick}>Save</Button>
                    <Button variant="secondary" size="sm" onClick={handleCancelClick}>Cancel</Button>
                </div>
            ) : (
                <div className="view-mode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onClick={handleCheckboxClick} // Use onClick to prevent changing the state
                        style={{ cursor: 'not-allowed' }}
                    />
                    <button className="edit-button" onClick={handleEditClick}>&#9998;</button>
                </div>
            )}
        </td>
    );
};