

function retrieveAllGroupsForUser(user) {
    const rawContent = JSON.stringify({
        "func": "get_all_groups_for_user",
        "user": user
    });
    const queryParams = "?func=get_all_groups_for_user&user=" + user;
    return makePostRequest(rawContent, queryParams);
}

function retrieveGroupShortlinksForUser(user, group) {
    const rawContent = JSON.stringify({
        "func": "get_group_shortlinks_for_user",
        "user": user,
        "group": group
    });
    const queryParams = "?func=get_group_shortlinks_for_user&user=" + user + "&group=" + group;
    return makePostRequest(rawContent, queryParams);
}

function retrieveGroupMembersForUser(user, group) {
    const rawContent = JSON.stringify({
        "func": "get_group_members_for_user",
        "user": user,
        "group": group
    });
    const queryParams = "?func=get_group_members_for_user&user=" + user + "&group=" + group;
    return makePostRequest(rawContent, queryParams);
}

function retrieveGroupDisplayNameForUser(user, group) {
    const rawContent = JSON.stringify({
        "func": "get_group_display_name",
        "user": user,
        "group": group
    });
    const queryParams = "?func=get_group_display_name&user=" + user + "&group=" + group;
    return makePostRequest(rawContent, queryParams);
}

function createGroup(user, group, description, domain, members) {
    const rawContent = JSON.stringify({
        "func": "create_group",
        "admin": user,
        "groupname": group,
        "description": description,
        "domain": domain,
        "invitees": members
    });
    const queryParams = "?func=create_group&admin=" + user + "&groupname=" + group + "&description=" + description + "&domain=" + domain + "&invitees=" + members;
    return makePostRequest(rawContent, queryParams);
}

function retrieveGroupDescriptionForUser(user, group) {
    const rawContent = JSON.stringify({
        "func": "get_group_description_for_user",
        "user": user,
        "group": group
    });
    const queryParams = "?func=get_group_description_for_user&user=" + user + "&group=" + group;
    return makePostRequest(rawContent, queryParams);
}

function retrieveGroupDomainMappingForUser(user, group) {
    const rawContent = JSON.stringify({
        "func": "get_group_domain_mapping",
        "user": user,
        "group": group
    });
    const queryParams = "?func=get_group_domain_mapping&user=" + user + "&group=" + group;
    return makePostRequest(rawContent, queryParams);
}

function inviteToGroup(user, group, invites, group_id) {
    const rawContent = JSON.stringify({
        "func": "invite_to_group",
        "admin": user,
        "groupname": group,
        "group_id": group_id,
        "invitee": invites
    });
    const queryParams = "?func=invite_to_group&admin=" + user + "&groupname=" + group + "&invitee=" + invites + "&group_id=" + group_id;
    return makePostRequest(rawContent, queryParams);
}

function removeUserFromGroup(groupname, admin, target) {
    const rawContent = JSON.stringify({
        "func": "remove_user_from_group",
        "groupname": groupname,
        "admin": admin,
        "target": target
    });
    const queryParams = "?func=remove_user_from_group&groupname=" + groupname + "&admin=" + admin + "&target=" + target;
    return makePostRequest(rawContent, queryParams);
}

function deleteGroup(groupname, admin) {
    const rawContent = JSON.stringify({
        "func": "delete_group",
        "groupname": groupname,
        "admin": admin
    });
    const queryParams = "?func=delete_group&groupname=" + groupname + "&admin=" + admin;
    return makePostRequest(rawContent, queryParams);
}

function deleteUser(user) {
    const rawContent = JSON.stringify({
        "func": "delete_user",
        "user": user
    });
    const queryParams = "?func=delete_user&user=" + user;
    return makePostRequest(rawContent, queryParams);

}

function updatePersonalMappingForGroup(user, groupname, new_domain) {
    const rawContent = JSON.stringify({
        "func": "update_personal_mapping_for_group",
        "user": user,
        "groupname": groupname,
        "new_domain": new_domain
    });
    const queryParams = "?func=update_personal_mapping_for_group&user=" + user + "&groupname=" + groupname + "&new_domain=" + new_domain;
    return makePostRequest(rawContent, queryParams);
}

function getPendingInvitesForUser(user) {
    const rawContent = JSON.stringify({
        "func": "get_pending_invites_for_user",
        "user": user
    });
    const queryParams = "?func=get_pending_invites_for_user&user=" + user;
    return makePostRequest(rawContent, queryParams);
}

function getPendingInvitesForGroup(user, groupname) {
    const rawContent = JSON.stringify({
        "func": "get_pending_invites_for_group",
        "user": user,
        "groupname": groupname
    });
    const queryParams = "?func=get_pending_invites_for_group&user=" + user + "&groupname=" + groupname;
    return makePostRequest(rawContent, queryParams);
}

function rescindInvite(user, group, target) {
    const rawContent = JSON.stringify({
        "func": "rescind_invite",
        "user": user,
        "groupname": group,
        "target": target
    });
    const queryParams = "?func=rescind_invite&user=" + user + "&groupname=" + group + "&target=" + target;
    return makePostRequest(rawContent, queryParams);
}

function respondToInvite(user, group, accepted, domain) {
    const rawContent = JSON.stringify({
        "func": "respond_to_invite",
        "user": user,
        "groupname": group,
        "accepted": accepted,
        "domain": domain
    });
    const queryParams = "?func=respond_to_invite&user=" + user + "&groupname=" + group + "&accepted=" + accepted + "&domain=" + domain;
    return makePostRequest(rawContent, queryParams);
}

function changeMemberStatusFromEmail(groupname, admin, target, status) {
    const rawContent = JSON.stringify({
        "func": "change_member_status_from_email",
        "groupname": groupname,
        "admin": admin,
        "target": target,
        "new_status": status
    });
    const queryParams = "?func=change_member_status_from_email&groupname=" + groupname + "&admin=" + admin + "&target=" + target + "&new_status=" + status;
    return makePostRequest(rawContent, queryParams);
}

function getSettingsForUser(user) {
    const rawContent = JSON.stringify({
        "func": "get_settings_for_user",
        "user": user
    });
    const queryParams = "?func=get_settings_for_user&user=" + user;
    return makePostRequest(rawContent, queryParams);
}

function createAliasForUser(user, alias, setError) {
    const rawContent = JSON.stringify({
        "func": "create_alias_for_user",
        "user": user,
        "alias": alias
    });
    const queryParams = "?func=create_alias_for_user&user=" + user + "&alias=" + alias;
    return makePostRequest(rawContent, queryParams, setError);
}

async function makePostRequest(rawContent, queryParams, setError) {
    console.log('Making POST request with raw content:', rawContent);

    const requestUrl = "https://bixygwf25zs2gzl4pgj4j4dksa0fessj.lambda-url.us-east-2.on.aws/";
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: rawContent
    };

    try {
        console.log('Before fetch');
        console.log('params:', params);
        const response = await fetch(requestUrl+queryParams, params);
        console.log('After fetch');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        console.log('Before response.json()');
        const data = await response.json();
        console.log('After response.json()');

        console.log('Response data:', data);

        if (data.error) {
            console.error('Error:', data.error);
            setError(data.error);
            return;
        }

        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export { retrieveAllGroupsForUser, retrieveGroupShortlinksForUser, retrieveGroupMembersForUser, retrieveGroupDisplayNameForUser, createGroup, retrieveGroupDomainMappingForUser, inviteToGroup, removeUserFromGroup, deleteGroup, updatePersonalMappingForGroup, getPendingInvitesForUser, respondToInvite, changeMemberStatusFromEmail, getPendingInvitesForGroup, rescindInvite, retrieveGroupDescriptionForUser, getSettingsForUser, createAliasForUser, deleteUser };