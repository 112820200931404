import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { View, Image, Text, useTheme } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';
import awsExports from './aws-exports';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';

import { ErrorProvider, ErrorAlert } from './components/Common/ErrorHandler';
import SiteNav from './components/Common/SiteNav';
import SiteFooter from './components/Common/SiteFooter';

import HomePage from './components/home/HomePage';
import LoginExtensionPage from './components/auth/LoginExtension';
import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
import ResetPasswordVerificationPage from './components/auth/ResetPasswordVerificationPage';
import SettingsPage from './components/home/SettingsPage';
import AboutPage from './components/home/About';
import ValidatePage from './components/auth/ValidatePage';
import Contacts from './components/contacts/Contacts';
import TablesPage from './components/tables/Tables';
import RerouterPage from './components/Rerouter/Rerouter';
import GroupPage from './components/groups/GroupPage';
import CreateGroup from './components/groups/CreateGroupPage';

Amplify.configure(awsExports);

// git add . && git commit -m "msg" && git push

function App() {
  // instead of setting to false, check the current auth status
  // Authenticator.authState === 'signedIn' ? true : false
  const [isAuthenticated, setIsAuthenticated] = useState();

    useEffect(() => {
    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false));
  }, []);
  

  function updateAuthStatus(authStatus) {
    setIsAuthenticated(authStatus)
  }

  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Amplify logo"
            src="/img/urlinkslogo2.png"
          />
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  };


// return (
//   <Authenticator loginMechanisms={['email']} components={components}>
//     {({ signOut, user }) => (
//   <div>
//     <SiteNav logOut={signOut}/>
//     <Routes>
//       <Route path='/*' element={<RerouterPage />} />
//       <Route path='/' exact={true} element={<HomePage />}/>
//       {/* <Route path='/login' element={<LoginPage />} />
//       <Route path='/register' element={<RegisterPage />} /> */}
//       {/* Add a SignOut page. */}
//       <Route path='/contacts' element={<Contacts />} />
//       <Route path='/tables' element={<Tables />} />
//       <Route path='/loginExtension' element={<LoginExtensionPage />} />
//     </Routes>
//     <SiteFooter />
//   </div>
//     )}
//   </Authenticator>
// );

return (
  <div>
    <SiteNav isAuthenticated={isAuthenticated} updateAuthStatus={updateAuthStatus} />
    <ErrorProvider>
    <ErrorAlert />
    <Routes>
      {/* <Route path='*' element={<HomePage isAuthenticated={isAuthenticated} />} /> */}
      <Route path='/*' element={<RerouterPage />} />
      <Route path='/' exact={true} element={<HomePage isAuthenticated={isAuthenticated} />} />
      <Route path='/home' exact={true} element={<HomePage isAuthenticated={isAuthenticated} />} />
      <Route path='/login' element={<LoginPage updateAuthStatus={updateAuthStatus} />} />
      <Route path='/register' element={<RegisterPage />} />
      <Route path='/forgotPassword' element={<ForgotPasswordPage />} />
      <Route path='/forgotPasswordVerification' element={<ResetPasswordVerificationPage />} />
      <Route path="/settings" element={<SettingsPage isAuthenticated={isAuthenticated} />} />
      <Route path='/validate' element={<ValidatePage />} />
      <Route path='/tables' element={<TablesPage />} />
      <Route path='/groups' element={<GroupPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/contact' element={<AboutPage />} />
      <Route path='/loginExtension' element={<LoginExtensionPage isAuthenticated={isAuthenticated}/>} />
      <Route path='/contacts' element={<Contacts isAuthenticated={isAuthenticated} />} />
      <Route path='/createGroup' element={<CreateGroup isAuthenticated={isAuthenticated} />} />
    </Routes>
    </ErrorProvider>
    {/* <SiteFooter /> */}
  </div>
);
}

export default App;
