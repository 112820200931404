import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './EditableCell.css';


export const EditableCell = ({ shortlink, description, onSave, isLink }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedDescription, setEditedDescription] = useState(description);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        onSave(shortlink.id, editedDescription);
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedDescription(description);
    };

    return (
        <td className="editable-cell">
            {isEditing ? (
                <div className="edit-mode">
                    <input 
                        type="text" 
                        value={editedDescription} 
                        onChange={(e) => setEditedDescription(e.target.value)}
                    />
                    <Button variant="primary" size="sm" onClick={handleSaveClick}>Save</Button>
                    <Button variant="secondary" size="sm" onClick={handleCancelClick}>Cancel</Button>
                </div>
            ) : (
                <div className="view-mode">
                    {isLink ? (
                        <a 
                            href={description.startsWith('http') ? description : `http://${description}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {description}
                        </a>
                    ) : (
                        <span>{description}</span>
                    )}
                    <button className="edit-button" onClick={handleEditClick}>
                        &#9998;
                    </button>
                </div>
            )}
        </td>
    );
};
