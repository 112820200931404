
import { getCurrentUser } from 'aws-amplify/auth';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function RerouterPage() {

  const params = useParams();
  console.log('starting rerouter page');

    useEffect(() => {
        const fetchData = async () => {
        try {
            const user = await getCurrentUser();
            console.log(user);
            const username = user.username;
            // const params = useParams();
            const redirect_site = 'https://rjs6tqja7zk7ccea3hgyg722bi0jndjw.lambda-url.us-east-2.on.aws/';
            console.log('Wildcard:', params['*']);
            window.location.href = redirect_site + params['*'] + "&u="+username;

        } catch (error) {
            console.error("Error fetching data", error);
        //  navigate('/tables#Groups');

        }
        };
    
        fetchData();
    }, []);
  
  return (
    <Container>
        <Row className="px-4 my-5">
            <Col xs={4} sm={6}>
            <Image src="/img/logo.png" fluid />
            </Col>
            <Col sm={6}>
                <h1 className="font-weight-light"> URLinks</h1>
                <p className="mt-4">You are being rerouted!</p>
                
            </Col>
        </Row>

    </Container>
  );
}
// return (
//     <Container>
//         <Row className="px-4 my-5">
//         </Row>

//     </Container>
//   );
// }

export default RerouterPage;