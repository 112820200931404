
// unused.
function retrieveUrlForShortlink(shortlink) {
    const rawContent = JSON.stringify({
        "func": "retrieve",
        "shortlink": shortlink
    });
    const urlParams = "?func=retrieve&shortlink=" + shortlink;
    return makePostRequest(rawContent, urlParams);
}

function retrieveAllShortlinksForUser(user) {
    console.log('Retrieving all shortlinks for user:', user);
    const rawContent = JSON.stringify({
        "func": "retrieve_all_shortlinks_for_user",
        "user": user
    });
    const urlParams = "?func=retrieve_all_shortlinks_for_user&user=" + user;
    return makePostRequest(rawContent, urlParams);
}

function retrieveAllShortlinksForGroup(user, group) {
    console.log('Retrieving all shortlinks for group:', group);
    const rawContent = JSON.stringify({
        "func": "retrieve_all_shortlinks_for_group",
        "user": user,
        "group": group
    });
    const urlParams = "?func=retrieve_all_shortlinks_for_group&user=" + user + "&group=" + group;
    return makePostRequest(rawContent, urlParams);
}

function retrieveAllGlobalShortlinks() {
    console.log('Retrieving all global shortlinks');
    const rawContent = JSON.stringify({
        "func": "retrieve_all_global_shortlinks",
    });
    const urlParams = "?func=retrieve_all_global_shortlinks";
    return makePostRequest(rawContent, urlParams);
}

function insertShortlink(user, shortlink, url, description, setError) {
    console.log('New shortlink for user:', user);
    const rawContent = JSON.stringify({
        "func": "insert",
        "user": user,
        "url": url,
        "shortlink": shortlink,
        "description": description,
    });
    const urlParams = "?func=insert&user=" + user + "&url=" + url + "&shortlink=" + shortlink + "&description=" + description;
    return makePostRequest(rawContent, urlParams, setError);
}

function deleteShortlink(user, shortlink) {
    console.log('Deleting shortlink for user:', user);
    const rawContent = JSON.stringify({
        "func": "removal",
        "user": user,
        "shortlink": shortlink,
    });
    const urlParams = "?func=removal&user=" + user + "&shortlink=" + shortlink;
    return makePostRequest(rawContent, urlParams);
}

function EditShortlinkField(user, shortlink, field, value) {
    console.log('Editing shortlink field for user:', user);
    const rawContent = JSON.stringify({
        "func": "edit_shortlink_field",
        "user": user,
        "shortlink": shortlink,
        "field": field,
        "new_value": value,
    });
    const urlParams = "?func=edit_shortlink_field&user=" + user + "&shortlink=" + shortlink + "&field=" + field + "&new_value=" + value;
    return makePostRequest(rawContent, urlParams);
}

function updateAliasForUser(user, alias, new_alias, setError) {
    console.log('Updating alias for user:', user);
    const rawContent = JSON.stringify({
        "func": "update_alias_for_user",
        "user": user,
        "alias": alias,
        "new_alias": new_alias,
    });
    const urlParams = "?func=update_alias_for_user&user=" + user + "&alias=" + alias + "&new_alias=" + new_alias;
    return makePostRequest(rawContent, urlParams, setError);
}

function deleteAliasForUser(user, alias, setError) {
    console.log('Deleting alias for user:', user);
    const rawContent = JSON.stringify({
        "func": "delete_alias_for_user",
        "user": user,
        "alias": alias,
    });
    const urlParams = "?func=delete_alias_for_user&user=" + user + "&alias=" + alias;
    return makePostRequest(rawContent, urlParams, setError);
}

async function makePostRequest(rawContent, urlParams, setError) {
    console.log('Making POST request with raw content:', rawContent);

    const requestUrl = "https://rjs6tqja7zk7ccea3hgyg722bi0jndjw.lambda-url.us-east-2.on.aws/";
    // for some reason, CORS breaks if I get rid of the url params.
    // const urlParams = "?func=retrieve_all_shortlinks_for_user&user=614e5ac7-aae9-4f0c-80a0-186f40e6826d";
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: rawContent
    };

    try {
        console.log('Before fetch');
        console.log('params:', params);
        const response = await fetch(requestUrl+urlParams, params);
        console.log('After fetch');
        console.log(response);


        console.log('Before response.json()');
        const data = await response.json();
        console.log('After response.json()');

        console.log('Response data:', data);

        if (data.error) {
            console.error('Error:', data.error);
            setError(data.error);
            return;
        }

        return data;
    } catch (error) {
        console.error('Error:', error);
        
    }
}

// function makePostRequest(rawContent) {
//     console.log('Making POST request with raw content:', rawContent);

//     const requestUrl = "https://rjs6tqja7zk7ccea3hgyg722bi0jndjw.lambda-url.us-east-2.on.aws/";

//     return new Promise((resolve, reject) => {
//         const xhr = new XMLHttpRequest();
//         xhr.open('POST', requestUrl, true);
//         xhr.setRequestHeader('Content-Type', 'application/json');

//         xhr.onload = function() {
//             if (this.status >= 200 && this.status < 300) {
//                 resolve(JSON.parse(xhr.response));
//             } else {
//                 reject({
//                     status: this.status,
//                     statusText: xhr.statusText
//                 });
//             }
//         };

//         xhr.onerror = function() {
//             reject({
//                 status: this.status,
//                 statusText: xhr.statusText
//             });
//         };

//         xhr.send(rawContent);
//     });
// }

export { retrieveUrlForShortlink, retrieveAllShortlinksForUser, insertShortlink, deleteShortlink, retrieveAllGlobalShortlinks, retrieveAllShortlinksForGroup, EditShortlinkField, updateAliasForUser, deleteAliasForUser };