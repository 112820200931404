import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import './HomePage.css';

function HomePage(props) {
    return (
        <Container>
            <Row className="px-4 my-5 justify-content-center">
                <Col sm={6} className="d-flex justify-content-center">
                    <Image src="/img/urlinkslogo2.png" fluid style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
            </Row>
            <Row className="px-4 my-5 justify-content-center">
                <Col sm={10} className="text-center">
                <h1 className="font-weight-light gradient-text">Browse Better, Share Better with URLinks</h1>                    
                <p style={{ color: '#1b3cbc'}}>
                        URLinks is based on a tool used by 100,000+ Google employees everyday, made publicly available for the first time.
                    </p>
                    <p style={{ color: '#1b3cbc', maxWidth: '82%', margin: '0 auto 20px auto' }}>
                        With the URLinks browser extension, you can navigate popular sites more quickly, create text-based shortcuts, and share memorable links with your friends.
                    </p>
                    {/* Call to action buttons */}
                    {
                        props.isAuthenticated === false && (
                            <>
                                <Link to='/login'>
                                    <Button variant="primary">Login &gt;&gt;</Button>
                                </Link>
                                &nbsp;&nbsp;
                                <Link to='/register'>
                                    <Button variant="primary">Register &gt;&gt;</Button>
                                </Link>
                            </>
                        )
                    }
                    {
                        props.isAuthenticated !== false && (
                            <Link to='/tables' state={{ authenticated: props.isAuthenticated }}>
                                <Button variant="primary">Explore URLinks &gt;&gt;</Button>
                            </Link>
                        )
                    }
                </Col>
            </Row>

            <Row className="px-4 my-5" style={{ marginTop: '2rem' }}>
                <Col sm={5} style={{ marginTop: '5rem' }}>
                    <h2 style={{ color: '#115ddb', textAlign: 'center', fontWeight: 'bold' }}>Skip the Landing</h2>
                    <p style={{ color: '#1b3cbc'}}>With the URLinks browser extension, you can reach the page you're looking for with a single query to the URL bar. No more intermediary landing pages or search results pages required. Want to find apartments for rent in your area? Try entering 'z/sf 1 bed rentals'. Need a quick laugh? Try 'yt/charlie bit me'. With over a dozen popular sites preconfigured, you'll be saving time in no time.</p>
                </Col>
                <Col sm={7} className="d-flex align-items-center justify-content-center">
                    <Image src="/img/urlinks_globals.png" fluid style={{ maxWidth: '100%', height: 'auto', border: '2px solid #0066CC' }} />
                </Col>
            </Row>

            {/* Feature 1 */}
            <Row className="px-4 my-5">
                <Col sm={6} style={{ marginTop: '5rem' }}>
                    <h2 style={{ color: '#115ddb', textAlign: 'center', fontWeight: 'bold' }}>Save your Faves</h2>
                    <p style={{ color: '#1b3cbc'}}>Use the extension popup on any page to make a memorable text-based Shortlink bookmark. Enter your Shortlink into the URL bar to get directly back to the page. You can also configure an alias to make your bookmarks publicly available for your friends who don't have URLinks yet.</p>
                </Col>
                <Col sm={6} className="d-flex align-items-center justify-content-center">
                    <Image src="/img/urlinks_ext.png" fluid style={{ maxWidth: '40%', height: 'auto', border: '2px solid #0066CC' }} />
                </Col>
            </Row>

            {/* Feature 2 */}
            <Row className="px-4 my-5">
                <Col sm={6} style={{ marginTop: '5rem' }}>
                    <h2 style={{ color: '#115ddb', textAlign: 'center', fontWeight: 'bold' }}>Collaboration Acceleration</h2>
                    <p style={{ color: '#1b3cbc'}}>URLinks Groups makes it easier than ever to organize your shared web resources with friends, family, and colleagues. Just say no to emailing URLs with random and confusing encodings!</p>
                </Col>
                <Col sm={6} className="d-flex align-items-center justify-content-center">
                    <Image src="/img/urlinks_groups.png" fluid style={{ maxWidth: '110%', height: 'auto', border: '2px solid #0066CC' }} />
                </Col>
            </Row>
    
            {/* Feature 3 */}
            {/* <Row className="px-4 my-5">
                <Col sm={6}>
                    <h2>Stay Organized and Enhance Collaboration!</h2>
                    <p>Do you find it challenging to manage and share web resources with your team, friends, or family? With URLinks Groups, you can effortlessly organize all your shared links in one place. Enhance collaboration and ensure everyone stays on the same page with URLinks.</p>
                </Col>
                <Col sm={6}>
                    <Image src="/img/group_page.png" fluid style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
            </Row> */}
    
            {/* Call to action */}
            {/* <Row className="px-4 my-5">
                <Col className="text-center">
                    <h2>Start using URLinks today!</h2>
                    <Link to='/register'>
                        <Button variant="outline-primary">Register &gt;&gt;</Button>
                    </Link>
                </Col>
            </Row> */}
        </Container>
    )
}

export default HomePage;