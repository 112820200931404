import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getCurrentUser } from 'aws-amplify/auth';

import { createGroup } from '../utils/AdminHandler';


function CreateGroup(props) {
    const navigate = useNavigate()

    const [groupName, setGroupName] = useState("");
    const [groupDescription, setGroupDescription] = useState("");
    const [domain, setDomain] = useState("");
    const [groupMembers, setGroupMembers] = useState([]);

    useEffect(() => {
        async function checkUser() {
            const user = await getCurrentUser();
            if (!user) {
                navigate('/');
            }
        }
        checkUser();
    }, []);


    const createGroupHandler = async (event) => {
        event.preventDefault();
        try {
        const user = await getCurrentUser();
        const username = user.username;

        await createGroup(username, groupName, groupDescription, domain, groupMembers);
        navigate('/tables#Groups');

            
    } catch(err) {
        console.error("Error adding new contact", err);
    }
    }

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>Create Group</h1></Col>
            </Row>
            <Row>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Group Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter group name" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Group Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter group description" value={groupDescription} onChange={(e) => setGroupDescription(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Domain</Form.Label>
                        <Form.Control type="text" placeholder="Enter shortlinks domain, e.g. my/" value={domain} onChange={(e) => setDomain(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Invitees</Form.Label>
                        <Form.Control 
                            type="textarea" rows={3}
                            placeholder="Enter email addresses, separated by commas" 
                            onChange={(e) => setGroupMembers(e.target.value.split(','))}
                        />
                    </Form.Group>

                    <Button variant="primary" onClick={createGroupHandler} type="submit">
                        Submit
                    </Button>
                </Form>
            </Row>
        </Container>
    );
}

export default CreateGroup;