import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

function LoginExtensionPage(props) {
  const navigate = useNavigate()

  if (props.isAuthenticated !== true) {
    // navigate('/Login#ext');
  }

  useEffect(() => {
    async function checkUser() {
        try {
            const user = await getCurrentUser();
            if (user) {
                redirectToUserPage();
            }
            if (!user) {
                navigate('/Login#ext');
            }
        } catch (error) {
            console.error("Error getting current user:", error);
            navigate('/Login#ext');
        }
    }
    checkUser();
}, []);

    async function getUsername() {
      const logged_in_user = await getCurrentUser();
      // console.log("Logged in user", logged_in_user);
      return logged_in_user.username;
    }

    async function getUserEmail() {
        const logged_in_user = await getCurrentUser();
        // console.log("Logged in user", logged_in_user);
        return logged_in_user.signInDetails.loginId;
      }
  // window.location.href = 'http://google.com#blank';
  // console.log(logged_in_user);
  async function redirectToUserPage() {
    const username = await getUsername();
    const userEmail = await getUserEmail();
    navigate('#' + username + '#' + userEmail);
  }
  
  // redirectToUserPage();
  // navigate('/loginExtension#' + logged_in_user.user.username + '#' + logged_in_user.user.signInDetails.loginId);

  let message = 'Sign Up or Log In';
  console.log(props.isAuthenticated);
  if (props.isAuthenticated == true) {
    console.log('Logged In')
    message = 'View Links';
  }
  
  return (
    <Container>
        <Row className="px-4 my-5">
            <Col xs={4} sm={6}>
            <Image src="/img/logo.png" fluid />
            </Col>
            <Col sm={6}>
                <h1 className="font-weight-light"> URLinks</h1>
                <p className="mt-4">URLinks is a simple link management tool that allows you to organize and share your frequently used links.</p>
                <Link to ={{ pathname: '/tables'}}>
                <Button variant="outline-primary">{message} &gt;&gt;</Button>
                </Link>
                
            </Col>
        </Row>

    </Container>
  );
}

export default LoginExtensionPage;