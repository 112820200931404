import React from 'react';

import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';

function SiteNav(props) {
    const navigate = useNavigate();
    
    const handleLogout = async () => {
        try {
            console.log('Logout');
            await signOut();

            props.updateAuthStatus(false);
            navigate('/?signOut=true');
        } catch (err) { console.log(err); }
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{
                cursor: 'pointer',
                color: '#ffffff',
                fontSize: '30px',
                transition: 'color 0.3s',
            }}
            onMouseEnter={(e) => e.target.style.color = '#cccccc'}
            onMouseLeave={(e) => e.target.style.color = '#ffffff'}
        >
            {children}
        </span>
    ));


    

    return (
        <header>
            <Navbar style={{ backgroundColor: '#1b3cbc' }} expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand>
                        <a href="/">
                            <img
                                src="/img/urlinkslogo2.png" // replace with your logo path
                                width="30"
                                height="30"
                                className="d-inline-block align-top me-2"
                                alt="URLinks"
                            />
                        </a>
                        <a href="/" className="navbar-brand">URLinks</a>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {
                            props.isAuthenticated !== false && (
                                <Nav className="ms-md-auto">
                                    <Dropdown align="end">
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                            &#9776; {/* Unicode character for hamburger menu */}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/tables">Your Shortlinks</Dropdown.Item>
                                            <Dropdown.Item href="/tables#Global">Global Shortlinks</Dropdown.Item>
                                            <Dropdown.Item href="/tables#Groups">Your Groups</Dropdown.Item>
                                            <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                                            <Dropdown.Item href="/about">About</Dropdown.Item>
                                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            )
                        }
                        {
                            props.isAuthenticated === false && (
                                <Nav className="ms-md-auto">
                                    <Nav.Link className="lighter-button" style={{ color: '#ffffff' }} href="/login">Login</Nav.Link>
                                    <Nav.Link className="lighter-button" style={{ color: '#ffffff' }} href="/register">Register</Nav.Link>
                                </Nav>
                            )
                        }            
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default SiteNav;