import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './EditableCell.css'; // Ensure this CSS includes the new styles for hover effect

export const EditableButtonCell = ({ shortlink, onDelete }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => setIsEditing(true);

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    return (
        <td className="editable-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', verticalAlign: 'middle' }}>
            {isEditing ? (
                <div className="edit-mode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="danger" size="sm" onClick={() => onDelete(shortlink.shortlink)}>Delete</Button>
                    <Button variant="secondary" size="sm" onClick={handleCancelClick}>Cancel</Button>
                </div>
            ) : (
                <div className="view-mode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="danger" size="sm" className="disabled-delete-button" disabled>Delete</Button>
                    <button className="edit-button" onClick={handleEditClick}>&#9998;</button>
                </div>
            )}
        </td>
    );
};