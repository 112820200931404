import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';

import { listShortlinkMaps } from '../../graphql/queries';
import { createShortlinkMap } from '../../graphql/mutations';

import { ErrorContext } from '../../components/Common/ErrorHandler';
import { EditableCell } from '../../components/Common/EditableCell';
import { EditableCheckboxCell } from '../../components/Common/EditableCheckboxCell';
import { EditableButtonCell } from '../../components/Common/EditableButtonCell';


import {retrieveAllShortlinksForUser, insertShortlink, deleteShortlink, retrieveAllGlobalShortlinks, EditShortlinkField} from '../utils/links_handler';
import {retrieveAllGroupsForUser, getPendingInvitesForUser, respondToInvite} from '../utils/AdminHandler';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

function Tables() {
    const [shortlinks, setShortlinks] = useState([]);
    const [ShortlinkData, setShortlinkData] = useState({name: "", shortlink: "", url: "", domain: "my/", description: "", public: false});
    const [globalShortlinks, setGlobalShortlinks] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [activeTab, setActiveTab] = useState('Your Shortlinks');
    const [groups, setGroups] = useState([]);
    const [user, setUser] = useState('');
    const [pendingInvites, setPendingInvites] = useState([]);

    const navigate = useNavigate();

    const { setError } = useContext(ErrorContext);

    async function getUser() {
      try {
        const logged_in_user = await getCurrentUser();
        if (!logged_in_user) {
          navigate('/login');
          return;
        }
        console.log("Logged in user", logged_in_user);
        setUser(logged_in_user.username);
      } catch (error) {
        console.error("Error fetching user", error);
        navigate('/login');
      }
    }

      const getShortlinks = async () => {
        try {
          console.log("getShortlinksV2");
          const user = await getCurrentUser();
          const username = user.username;
          console.log('Username', username);
          // setShortlinks([]);
          const shortlinksList = await retrieveAllShortlinksForUser(username) || [];
          console.log("Shortlinks", shortlinksList);
          if (shortlinksList.length === 0 && !(window.location.hash.endsWith('Groups'))) {
            console.log('Display global condition met.');
          }
          console.log("Shortlinks", shortlinksList);

          setShortlinks(shortlinksList);

          // for (let shortlink of shortlinksList) {
          //   // console.log(shortlink);
          //   if (shortlink.public) {
          //     console.log('Public shortlink:', shortlink);
          //   }
          // }
      } catch(err) {
          console.error("Error fetching shortlinks", err);
      }
      }

      useEffect(() => {
        console.log("useEffect");
        getShortlinks();
        getUser();
      }, []);

      useEffect(() => {
        if (window.location.hash.endsWith('Groups')) {
          setActiveTab('Your Groups');
        }
        if (window.location.hash.endsWith('Global')) {
          setActiveTab('Global Shortlinks');
        }
      }, []);

      const updateUrlHash = (tab) => {
        window.history.replaceState(null, null, `#${tab}`);
      };
      
      useEffect(() => {
        console.log('Active Tab', activeTab);
        let activeTabShortcut = "";
        switch (activeTab) {
          case 'Your Shortlinks':
            activeTabShortcut = '';
            break;
          case 'Global Shortlinks':
            activeTabShortcut = 'Global';
            break;
          case 'Your Groups':
            activeTabShortcut = 'Groups';
            break;
          default:
            activeTabShortcut = '';
            break;
        }

        updateUrlHash(activeTabShortcut);
      }, [activeTab]);

      useEffect(() => {
        const fetchGroups = async () => {
          try {
            const user = await getCurrentUser();
            if (!user) {
              console.log("No user found");
              return;
            }
            const username = user.username;
            const userGroups = await retrieveAllGroupsForUser(username);
            console.log("User Groups", userGroups);
            setGroups(userGroups);
          } catch (error) {
            console.error("Failed to fetch groups:", error);
          }
        };
      
        fetchGroups();
      }, []);
      
      useEffect(() => {
        console.log('attempting to fetch pending invites. User:', user);
        const fetchPendingInvites = async () => {
          try {
            const user = await getCurrentUser();
            if (!user) {
              console.log("No user found");
              return;
            }
            const username = user.username;
            console.log('Fetching pending invites for user:', username);
            // weird behavior on the serverside that I need to cast this to a string...
            const invites = await getPendingInvitesForUser(String(username));
            console.log('Pending Invites', invites);
            setPendingInvites(invites);
          } catch (error) {
            console.error("Failed to fetch pending invites:", error);
          }
        };
      
        fetchPendingInvites();
      }, [user]);


  const addNewShortlink = async () => {
    try {
      const { name, shortlink, url, domain, description } = ShortlinkData;

      const user = await getCurrentUser();
      const username = user.username;
      console.log('making a new shortlink.');
      console.log(domain + shortlink);
      await insertShortlink(username, domain + shortlink, url, description, setError);
      await getShortlinks();
      
      // window.location.reload()

    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

  const deleteShortlinkHelper = async (shortlink) => {
    try {
      console.log('Deleting shortlink:', shortlink);
      const user = await getCurrentUser();
      const username = user.username;
      console.log('making a new shortlink.');
      await deleteShortlink(username, shortlink);
      await getShortlinks();
    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

  const editShortlinkHelper = async (shortlink, field, newValue) => {
    try {
      console.log('Edit shortlink:', shortlink);
      const user = await getCurrentUser();
      const username = user.username;
      await EditShortlinkField(username, shortlink, field, newValue);
      const groupShortlinks = await retrieveAllShortlinksForUser(username);
      setShortlinks(groupShortlinks);
    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

  const retrieveAllGlobalShortlinksHelper = async () => {
    try {
      const globalShortlinks = await retrieveAllGlobalShortlinks();
      setGlobalShortlinks(globalShortlinks);
      console.log("Global Shortlinks", globalShortlinks);
    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

  useEffect(() => {
    const fetchGlobalShortlinks = async () => {
      await retrieveAllGlobalShortlinksHelper();
      // setGlobalShortlinks(shortlinks);
    };
  
    fetchGlobalShortlinks();
  }, []);


  return (
    <Container>
            <Row className="px-4 my-5">
                <Col><h1>Shortlinks</h1></Col>
            </Row>
            <Row className="px-4 my-5">
    <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} style={{ marginLeft: '12px', paddingRight: '5px' }}>
      <Tab eventKey="Your Shortlinks" title="Your Shortlinks">
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Shortlink</th>
          <th>Url</th>
          <th>Description</th>
          <th>
            Publicly Visible?
            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <OverlayTrigger
                  placement="right"
                  overlay={
                      <Tooltip id="tooltip-right">
                          Configure an alias in the settings page to make your links publicly available.
                      </Tooltip>
                  }
              >
                  <Button variant="secondary" size="sm" style={{ background: 'none', border: 'none', padding: 0 }}>
                      <i className="fas fa-info-circle" style={{ color: '#007bff' }}></i>
                  </Button>
              </OverlayTrigger>
          </td>
          </th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {(shortlinks || []).map((shortlink, index) => {
          return (
            <tr key={index}>
              <td>{shortlink.shortlink}</td>
              <EditableCell 
                  shortlink={shortlink} 
                  description={shortlink.url} 
                  onSave={(id, newUrl) => { editShortlinkHelper(shortlink.shortlink, 'url', newUrl); }}
                  isLink={true} 
              />
              <EditableCell 
                  shortlink={shortlink} 
                  description={shortlink.description} 
                  onSave={(id, newDescription) => { editShortlinkHelper(shortlink.shortlink, 'description', newDescription); }}
                  isLink={false} 
              />
              {/* editable checkbox */}
              <EditableCheckboxCell 
                  shortlink={shortlink} 
                  isChecked={shortlink.public} 
                  onSave={(id, newVisibility) => { editShortlinkHelper(shortlink.shortlink, 'public', newVisibility); }}
              />
              <EditableButtonCell 
                  shortlink={shortlink} 
                  onDelete={deleteShortlinkHelper} 
              />
                {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <Button variant="danger" onClick={() => deleteShortlinkHelper(shortlink.shortlink)}>
                        X
                    </Button>
                </td> */}

            </tr>
          )
        })}
      </tbody>
    </Table>
    <Row className="px-4 my-5">
                <h2>Add New Shortlink</h2>
                <Form>
                    {/* <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Shortlink</Form.Label>
                        <Form.Control type="text" placeholder="Shortlink" 
                                        value={ShortlinkData.shortlink}
                                        onChange={evt => setShortlinkData({...ShortlinkData, shortlink:evt.target.value})}/>
                    </Form.Group> */}
                    <Row>
                    <Col sm={2}>
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Domain</Form.Label>
                        <Form.Select aria-label="Select domain" onChange={evt => setShortlinkData({...ShortlinkData, domain:evt.target.value})}>
                        <option value="my/">my/</option>
                          {groups.map((group, index) => (
                            <option key={index} value={group.domain}>{group.domain}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={10}>
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Shortlink</Form.Label>
                        <Form.Control type="text" placeholder="Shortlink" 
                                      value={ShortlinkData.shortlink}
                                      onChange={evt => setShortlinkData({...ShortlinkData, shortlink:evt.target.value})}/>
                      </Form.Group>
                    </Col>
                  </Row>
                    {/* </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Url</Form.Label>
                        <Form.Control type="text" placeholder="Url" 
                                        value={ShortlinkData.url}
                                        onChange={evt => setShortlinkData({...ShortlinkData, url:evt.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Description </Form.Label>
                        <Form.Control type="text" placeholder="Optional" 
                                        value={ShortlinkData.description}
                                        onChange={evt => setShortlinkData({...ShortlinkData, description:evt.target.value})}/>
                    </Form.Group>
                    <Button variant="primary" type="button" onClick={addNewShortlink}>
                        Add Shortlink &gt;&gt;
                    </Button>&nbsp;
                    {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                    
                </Form>

            </Row>
      </Tab>
      <Tab eventKey="Global Shortlinks" title="Global Shortlinks">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Shortlink</th>
            <th>URL</th>
            <th>Capabilities</th>
          </tr>
        </thead>
        <tbody>
          {(globalShortlinks || []).sort((a, b) => a.id.localeCompare(b.id)).map((shortlink, index) => (
            <tr key={index}>
              <td>{shortlink.id}</td>
              <td>
                <a 
                  href={shortlink.url.startsWith('http') ? shortlink.url : `http://${shortlink.url}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {shortlink.url}
                </a>
              </td>
              <td>{shortlink.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Tab>
      <Tab eventKey="Your Groups" title="Your Groups">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Domain</th>
            </tr>
          </thead>
          <tbody>
            {(groups || []).map((group, index) => (
              <tr key={index}>
                <td>
                  <a href={`http://${window.location.hostname === 'localhost' ? 'localhost:3000' : 'urlinks.me'}/groups?g=${group?.unique_name}`}>
                    {group?.display_name}
                  </a>
                </td>
                <td>{group?.domain}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {pendingInvites && Object.keys(pendingInvites).length > 0 && (
          <>
          <h2 className="mt-3">Pending Invites</h2>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Group Name</th>
                <th>Domain</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pendingInvites.map((currPendingInvite, index) => (
                <tr key={index}>
                  <td>{currPendingInvite.displayname}</td>
                  {/* TODO make the domain button editable when there is a conflict. */}
                  <td style={{ color: groups.some(group => group.domain === currPendingInvite.domain) ? 'red' : 'inherit' }}>
                    {currPendingInvite.domain}
                  </td>
                  <td>
                    <span 
                      title={groups.some(group => group.domain === currPendingInvite.domain) ? "Please update your personal domain for the group with the conflicting domain to accept this invite." : ""}
                    >
                      <Button 
                        variant="success" 
                        disabled={groups.some(group => group.domain === currPendingInvite.domain)}
                        onClick={() => {respondToInvite(user, currPendingInvite.groupname, true, currPendingInvite.domain);
                          navigate('/tables#Groups');
                        }}
                      >
                        Accept
                      </Button>
                    </span>
                    <Button variant="danger" onClick={() => {respondToInvite(user, currPendingInvite.groupname, false, currPendingInvite.domain);
                          navigate('/tables#Groups');
                        
                    }}>
                      Reject
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </>
              )}
        <div className="d-flex justify-content-end">
        <Link to="/createGroup">
          <Button variant="primary" className="mt-3">
            Create New Group
          </Button>
        </Link>
        </div>
      </Tab>
    </Tabs>
    </Row>
    {/* <Row className="px-4 my-5">
                <h2>Add New Shortlink</h2>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Shortlink</Form.Label>
                        <Form.Control type="text" placeholder="Shortlink" 
                                        value={ShortlinkData.shortlink}
                                        onChange={evt => setShortlinkData({...ShortlinkData, shortlink:evt.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Url</Form.Label>
                        <Form.Control type="text" placeholder="Url" 
                                        value={ShortlinkData.url}
                                        onChange={evt => setShortlinkData({...ShortlinkData, url:evt.target.value})}/>
                    </Form.Group>
                    <Button variant="primary" type="button" onClick={addNewShortlink}>
                        Add Shortlink &gt;&gt;
                    </Button>&nbsp;
                    {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                    
                </Form>

            </Row> */}
    </Container>
  );
}

export default Tables;