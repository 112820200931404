import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { signIn, signOut, getCurrentUser } from 'aws-amplify/auth';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function LoginPage(props) {
    const navigate = useNavigate()
    
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            console.log('Login');
            console.log(username);
            console.log(password);
            
            await signOut();

        //     await signIn({username: username, 
        //         password: password})

        //     props.updateAuthStatus(true)
        //     console.log('AuthStatus:' + props.isAuthenticated)
        //     navigate('/contacts')
        // } catch (err) { console.log(err) }
        await signIn({username: username, password: password})
            .then(async () => {
                props.updateAuthStatus(true);
                console.log('AuthStatus:' + props.isAuthenticated);

                if (window.location.hash.endsWith('ext')) {
                const username = await getUsername();
                const userEmail = await getUserEmail();
                navigate('/#' + username + '#' + userEmail);
                } else {
                navigate('/tables');
                }
            })
            .catch(err => console.log(err));
    } catch (err) { console.log(err) }
    }

    async function getUsername() {
        const logged_in_user = await getCurrentUser();
        // console.log("Logged in user", logged_in_user);
        return logged_in_user.username;
      }
  
      async function getUserEmail() {
          const logged_in_user = await getCurrentUser();
          // console.log("Logged in user", logged_in_user);
          return logged_in_user.signInDetails.loginId;
        }

    return (
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>Login</h1></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email Address"
                                onChange={evt => setUserName(evt.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" minLength="8" placeholder="Enter Password"
                                onChange={evt => setPassword(evt.target.value)} />
                        </Form.Group>

                        <Button variant="primary" type="button"
                            onClick={handleLogin}>Login &gt;&gt;</Button>
                        &nbsp;&nbsp;
                        <Link
                            to='/register'>
                            <Button variant="outline-primary">Register</Button>
                        </Link>
                        &nbsp;&nbsp;
                        <Link
                            to='/'>
                            <Button variant="outline-primary">Cancel</Button>
                        </Link>
                        <Link
                            to='/ForgotPassword'>
                            <Button variant="link">Forgot Password</Button>
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginPage;