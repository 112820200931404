import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';

import { retrieveGroupMembersForUser, retrieveGroupDisplayNameForUser, retrieveGroupDomainMappingForUser, inviteToGroup,  removeUserFromGroup, deleteGroup, updatePersonalMappingForGroup, changeMemberStatusFromEmail, getPendingInvitesForGroup, rescindInvite, retrieveGroupDescriptionForUser} from '../utils/AdminHandler';
import { deleteShortlink, insertShortlink, retrieveAllShortlinksForGroup, EditShortlinkField } from '../utils/links_handler';

import { ErrorContext } from '../../components/Common/ErrorHandler';
import { EditableCell } from '../../components/Common/EditableCell';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function GroupPage() {
  const location = useLocation();
  const groupName = new URLSearchParams(location.search).get('g');

  const [shortlinks, setShortlinks] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [members, setMembers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [domain, setDomain] = useState('');
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [ShortlinkData, setShortlinkData] = useState({name: "", shortlink: "", url: "", domain: "", description: ""});
  const [invites, setInvites] = useState({invites: ""});
  const [newDomain, setNewDomain] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedMembers, setEditedMembers] = useState({});
  const [pendingInvites, setPendingInvites] = useState([]);
  const [groupDescription, setGroupDescription] = useState('');

  
  const navigate = useNavigate();

  const { setError } = useContext(ErrorContext);

  useEffect(() => {
    const fetchData = async () => {
    try {
        const user = await getCurrentUser();
        console.log(user);
        const username = user.username;
        const groupShortlinks = await retrieveAllShortlinksForGroup(username, groupName);
        const groupMembers = await retrieveGroupMembersForUser(username, groupName);
        const groupDisplayName = await retrieveGroupDisplayNameForUser(username, groupName);
        const groupDescription = await retrieveGroupDescriptionForUser(username, groupName);
        const groupDomain = await retrieveGroupDomainMappingForUser(username, groupName);
        const pendingInvites = await getPendingInvitesForGroup(username, groupName);
        console.log(groupShortlinks);
        setUsername(username);
        setShortlinks(groupShortlinks);
        setMembers(groupMembers);
        setDisplayName(groupDisplayName);
        setUserEmail(user.signInDetails.loginId);
        console.log(groupDomain);
        setDomain(groupDomain);
        setShortlinkData(prevState => ({...prevState, domain: groupDomain}));
        const isAdmin = groupMembers.some(member => member.email_address === user.signInDetails.loginId && member.is_admin == 'admin');
        setIsAdmin(isAdmin);
        console.log(pendingInvites);
        setPendingInvites(pendingInvites);
        setGroupDescription(groupDescription);
    } catch (error) {
        console.error("Error fetching data", error);
       //  navigate('/tables#Groups');

    }
    };
  
    fetchData();
  }, [groupName]);



  const addNewShortlink = async () => {
    try {
      const { name, shortlink, url, domain, description } = ShortlinkData;

      const user = await getCurrentUser();
      const username = user.username;
      console.log('making a new shortlink.');
      console.log(domain + shortlink);
      await insertShortlink(username, domain + shortlink, url, description, setError);
      const groupShortlinks = await retrieveAllShortlinksForGroup(username, groupName);
      setShortlinks(groupShortlinks);

      
      // window.location.reload()

    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

  const deleteShortlinkHelper = async (shortlink) => {
    try {
      console.log('Deleting shortlink:', shortlink);
      const user = await getCurrentUser();
      const username = user.username;
      console.log('making a new shortlink.');
      await deleteShortlink(username, shortlink);
      const groupShortlinks = await retrieveAllShortlinksForGroup(username, groupName);
      setShortlinks(groupShortlinks);
    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

  const editShortlinkHelper = async (shortlink, field, newValue) => {
    try {
      console.log('Edit shortlink:', shortlink);
      const user = await getCurrentUser();
      const username = user.username;
      await EditShortlinkField(username, shortlink, field, newValue);
      const groupShortlinks = await retrieveAllShortlinksForGroup(username, groupName);
      setShortlinks(groupShortlinks);
    } catch(err) {
      console.error("Error adding new shortlink", err);
  }
  }

    const handleInvite = async (e) => {
        e.preventDefault();
        try {
            const user = await getCurrentUser();
            const username = user.username;
            console.log('Inviting to group:', invites);
            console.log('gorupNmae:', groupName);
            // breakup invites by comma or semicolon or whitspace and call inviteToGroup in a loop
            const newInvites = invites.split(/,|;|\s/);
            console.log('newInvites:', newInvites);
            for (let i = 0; i < newInvites.length; i++) {
                console.log('newInvites[i]:', newInvites[i]);
                await inviteToGroup(username, displayName, newInvites[i], groupName);
            }
            // await inviteToGroup(username, groupName, invites);
            // window.location.reload()

        } catch(err) {
            console.error("Error adding new member", err);
        }
    }

    const handleEdit = () => {
        setIsEditing(!isEditing);
      };

    const handleDropdownChange = (member, value) => {
        setEditedMembers({ ...editedMembers, [member.email_address]: value });
      };

    const handleSave = () => {
        Object.entries(editedMembers).forEach(([email, isAdmin]) => {
            console.log('hi');
            changeMemberStatusFromEmail(groupName, username, email, isAdmin);
        //   changeIsAdmin(email, isAdmin);
        });
        setIsEditing(false);
        window.location.reload()
      };

  return (
    <Container>
        <Row className="px-4 my-5">
        <Col>
            <Link to="/tables#Groups">
            <Button variant="outline-secondary" style={{ position: 'relative', left: '-100px', top: '-30px' }}>&lt; Back</Button>
            </Link>
        </Col>
        </Row>
        <Row>
        <Col xs={12} className="mt-2">
            <h1 className="text-start">{displayName}</h1>
            <p className="text-start" style={{ fontSize: 'small' }}>{groupDescription}</p> {/* New line */}
        </Col>
        </Row>
            <Row className="px-4 mt-4"></Row>
      <Tabs>
        <Tab eventKey="Shortlinks" title="Shortlinks">
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Shortlink</th>
                    <th>URL</th>
                    <th>Description</th>
                    <th>Creator</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {(shortlinks || []).map((shortlink, index) => (
                    <tr key={index}>
                    <td>{shortlink.shortlink}</td>
                    {(isAdmin || (shortlink.owners && shortlink.owners.includes(userEmail))) ? (
                        <EditableCell 
                            shortlink={shortlink} 
                            description={shortlink.url} 
                            onSave={(id, newUrl) => { editShortlinkHelper(shortlink.shortlink, 'url', newUrl); }} 
                            isLink={true}
                        />
                    ) : (
                        <td>
                            <a 
                                href={shortlink.url.startsWith('http') ? shortlink.url : `http://${shortlink.url}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {shortlink.url}
                            </a>
                        </td>
                    )}
                    {(isAdmin || (shortlink.owners && shortlink.owners.includes(userEmail))) ? (
                        <EditableCell 
                            shortlink={shortlink} 
                            description={shortlink.description} 
                            onSave={(id, newDescription) => { editShortlinkHelper(shortlink.shortlink, 'description', newDescription); 
                                
                            }}
                            isLink={false} 
                        />
                    ) : (
                        <td>{shortlink.description}</td>
                    )}
                    <td>
                        {shortlink.owners}
                    </td>
                    <td style={{textAlign: "center", verticalAlign: "middle"}}>
                    {(isAdmin || (shortlink.owners && shortlink.owners.includes(userEmail))) ? (
                        <Button variant="danger" onClick={() => deleteShortlinkHelper(shortlink.shortlink)}>
                        X
                        </Button>
                    ) : null}
                    </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Row className="px-4 my-5">
                <h2>Add New Shortlink</h2>
                <Form>
                    <Row>
                    <Col sm={2}>
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Domain</Form.Label>
                        <Form.Control plaintext readOnly value={domain} />
                      </Form.Group>
                    </Col>
                    <Col sm={10}>
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Shortlink</Form.Label>
                        <Form.Control type="text" placeholder="Shortlink" 
                                      value={ShortlinkData.shortlink}
                                      onChange={evt => setShortlinkData({...ShortlinkData, shortlink:evt.target.value})}/>
                      </Form.Group>
                    </Col>
                  </Row>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Url</Form.Label>
                        <Form.Control type="text" placeholder="Url" 
                                        value={ShortlinkData.url}
                                        onChange={evt => setShortlinkData({...ShortlinkData, url:evt.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Description </Form.Label>
                        <Form.Control type="text" placeholder="Optional" 
                                        value={ShortlinkData.description}
                                        onChange={evt => setShortlinkData({...ShortlinkData, description:evt.target.value})}/>
                    </Form.Group>
                    <Button variant="primary" type="button" onClick={addNewShortlink}>
                        Add Shortlink &gt;&gt;
                    </Button>&nbsp;
                    {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                    
                </Form>

            </Row>
            </Tab>
        <Tab eventKey="Members" title="Members">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {(members || []).map((member, index) => (
                    <tr key={index}>
                        <td>{member.email_address}</td>
                        <td>
                        {isEditing ? (
                            <select defaultValue={member.is_admin} onChange={(e) => handleDropdownChange(member, e.target.value)}>
                            <option value="member">member</option>
                            <option value="admin">admin</option>
                            <option value="remove">remove</option>
                            </select>
                        ) : (
                            member.is_admin
                        )}
                        </td>
                    </tr>
                    ))}

{isAdmin && (
  <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
    <button 
      onClick={handleEdit} 
      style={{
        backgroundColor: isEditing ? '#808080' : '#0C6DFD',
        border: 'none',
        color: 'white',
        padding: '15px 32px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '5px'
      }}
    >
      {isEditing ? 'Cancel' : 'Edit Member Status'}
    </button>
    {isEditing && (
      <button 
        onClick={handleSave} 
        style={{
          backgroundColor: '#0C6DFD', /* Blue */
          border: 'none',
          color: 'white',
          padding: '15px 32px',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '16px',
          margin: '4px 2px',
          cursor: 'pointer',
          borderRadius: '5px'
        }}
      >
        Save
      </button>
    )}
  </div>
)}

                </tbody>
            </Table>
            </div>

            {pendingInvites && pendingInvites.length > 0 && (
                <div>
                    <h2>Pending Invites</h2>
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Name</th>
                        {isAdmin && <th>Action</th>} {/* New column header */}
                        </tr>
                    </thead>
                    <tbody>
                        {pendingInvites.map((invite, index) => (
                        <tr key={index}>
                            <td>{invite}</td>
                            {isAdmin && ( <td> {/* New column data */}
                            <Button variant="danger" onClick={() => {rescindInvite(username, groupName, invite); window.location.reload();}}>
                                Rescind Invite
                            </Button>
                            </td>
                            )}
                        </tr>
                        ))}
                    </tbody>
                    </Table>
                </div>
                )}
            
            {isAdmin ? (
                <Form onSubmit={handleInvite} className="mt-5">
                <Row>
                  <Col md={10}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h2>Invite More to Join</h2>
                      <Form.Control 
                        type="text" 
                        placeholder="Enter email addresses, separated by commas" 
                        onChange={(e) => setInvites(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2} className="d-flex align-items-center" style={{ marginTop: '28px' }}>
                    <Button variant="primary" type="submit">
                      Invite
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : null}
        </Tab>
        <Tab eventKey="Settings" title="Settings">
        <Form className="mt-3">
        <Form.Group className="mb-3">
            <Form.Label>Personal Domain</Form.Label>
            <Row>
                <Col md={8}>
                <Form.Control 
                    type="text" 
                    placeholder={`Update your personal domain for this group, currently: ${domain}`}
                    value={newDomain}
                    onChange={(e) => setNewDomain(e.target.value)}
                />
                </Col>
                <Col md={4} className="d-flex align-items-center">
                <Button variant="primary" type="submit" onClick={() => updatePersonalMappingForGroup(username, groupName, newDomain)}>
                    Change Domain
                </Button>
                </Col>
            </Row>
            </Form.Group>
            </Form>
            <div className="mt-3">
                <Button variant="secondary" onClick={() => {
                    removeUserFromGroup(groupName, username, username); 
                    navigate('/tables#Groups')}}>
                    Leave Group
                </Button>
                </div>
            {isAdmin ? (
                <div className="mt-3">
                <Button variant="danger" onClick={() => deleteGroup(groupName, username)}>
                    Delete Group
                </Button>
                </div>
            ) : null}
            
            </Tab>
      </Tabs>
    </Container>
  );
}

export default GroupPage;