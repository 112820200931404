import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
  return (
    <Container>
      <Row className="my-5">
        <Col>
          <h2>About Us</h2>
          <p>
            URLinks is based on Go Links, a tool used internally within Google for 10+ years. I created URLinks because there was no third party solution that solves the same problems that Go Links does.
          </p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <h2>Contact Us</h2>
          <p>
          For questions, comments, concerns, issues, bug reports, suggestions, advice, recommendations, help, inquiries, ideas, opportunities, feedback, feature requests, troubleshooting, technical support, best practices, or anything else, please email me at admin@urlinks.me.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;