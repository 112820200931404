import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Accordion, Card } from 'react-bootstrap';

import { getCurrentUser } from 'aws-amplify/auth';
import { getSettingsForUser, createAliasForUser, deleteUser } from '../utils/AdminHandler';
import { ErrorContext } from '../../components/Common/ErrorHandler';
import { updateAliasForUser, deleteAliasForUser } from '../utils/links_handler';

function SettingsPage(props) {
    const [newAlias, setNewAlias] = useState('');
    const [hasAlias, setHasAlias] = useState(false);
    const [currentAlias, setCurrentAlias] = useState('');

    const navigate = useNavigate();

    const { setError } = useContext(ErrorContext);

    useEffect(() => {
        async function checkUser() {
            const user = await getCurrentUser();
            if (!user) {
                navigate('/');
            }
        }
        checkUser();
    }, []);

    const handleCreate = async () => {
        // Implement your alias creation or update logic here
        try {
            const user = await getCurrentUser();
            const result = await createAliasForUser(user.username, newAlias, setError);
            console.log(result);
        }
        catch (error) {
            console.error('Failed to create alias: ', error);
            // setError('Failed to create alias. Please try again.');
        }
        console.log('Alias submitted:', newAlias);
    };

    const handleDelete = async () => {
        // Implement your alias deletion logic here
        try {
            const user = await getCurrentUser();
            deleteAliasForUser(user.username, currentAlias, setError);
        }
        catch (error) {
            console.error('Failed to delete alias: ', error);
            // setError('Failed to delete alias. Please try again.');
        }

        console.log('Alias deleted');
    };

    const handleUpdate = async (event) => {
        // Implement your alias deletion logic here
        try {
            event.preventDefault();
            const user = await getCurrentUser();
            const result = await updateAliasForUser(user.username, currentAlias, newAlias, setError);
            console.log('Alias updated');
            console.log(result);
        }
        catch (error) {
            console.error('Failed to update alias: ', error);
            // setError('Failed to update alias. Please try again.');
        }
        
    };

    const handleDeleteAccount = async (event) => {
        try {
            event.preventDefault();
            const user = await getCurrentUser();
            const result = await deleteUser(user.username);
            console.log('Account deleted');
            console.log(result);
        } catch (error) {
            console.error('Failed to delete account: ', error);
            // setError('Failed to delete account. Please try again.');
        }
    };

    useEffect(() => {
        async function checkUser() {
            const user = await getCurrentUser();
            if (!user) {
                navigate('/');
            } else {
                const settings = await getSettingsForUser(user.username);
                console.log(settings);
                if (settings && settings.has_alias) {
                    setHasAlias(true);
                    setCurrentAlias(settings.alias);
                }
            }
        }
        checkUser();
    }, []);

    return (
        <Container>
            <Row className="justify-content-md-center my-5">
                <Col xs={12} md={8}>
                    <h2 style={{ marginBottom: '50px' }}>Settings</h2>
                    <Accordion defaultActiveKey="0">
                        {!hasAlias ? (
                            <Accordion>
                                <Card>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <strong>Create Alias</strong>
                                        </Accordion.Header>
                                        <Accordion.Body as={Card.Body}>
                                            <p style={{ fontSize: '18px' }}>Create an alias to make your links publicly available.</p>
                                            <p style={{ fontSize: '16px' }}>For instance, if your alias is 'blank', your links can be reached at urlinks.me/u/blank/link.</p>
                                            <p>Alias Rules:</p>
                                            <ul>
                                                <li>Must be at least 5 characters.</li>
                                                <li>Can only contain alphanumeric characters.</li>
                                                <li>Case insensitive.</li>
                                                <li>Must be unique.</li>
                                            </ul>
                                            <Form onSubmit={handleCreate}>
                                                <Form.Group controlId="formAlias">
                                                    <Form.Label>Alias</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter alias"
                                                        value={newAlias}
                                                        onChange={(e) => setNewAlias(e.target.value)}
                                                    />
                                                </Form.Group>
                                                <Button variant="primary" type="submit">
                                                    Create Alias
                                                </Button>
                                            </Form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Card>
                            </Accordion>
                        ) : (
                            <>
                                <Card>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <strong>Update Alias</strong>
                                            </Accordion.Header>
                                            <Accordion.Body as={Card.Body}>
                                                <Form onSubmit={handleUpdate}>
                                                    <Form.Group controlId="formAlias">
                                                        <p>Alias Rules:</p>
                                                        <ul>
                                                            <li>Must be at least 5 characters.</li>
                                                            <li>Can only contain alphanumeric characters.</li>
                                                            <li>Case insensitive.</li>
                                                            <li>Must be unique.</li>
                                                        </ul>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Enter new alias, your current alias is: "${currentAlias}"`}
                                                            value={newAlias}
                                                            onChange={(e) => setNewAlias(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="primary" type="submit">
                                                        Update Alias
                                                    </Button>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card>
                                <Card className="mt-3">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><strong>Delete Alias</strong></Accordion.Header>
                                        <Accordion.Body>
                                            <h4 className="font-weight-bold">This action cannot be undone.</h4>
                                            <p>This will mark all of your public shortlinks as private.</p>
                                            <Button variant="danger" onClick={handleDelete}>
                                                Delete Your Alias
                                            </Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Card>
                            </>
                        )}
                        <Card className="mt-3">
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><strong>Delete Account</strong></Accordion.Header>
                                <Accordion.Body>
                                    <h4 className="font-weight-bold">This action cannot be undone.</h4>
                                    <p>Deleting your account will remove all your data permanently.</p>
                                    <Button variant="danger" onClick={handleDeleteAccount}>
                                        Delete Your Account
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default SettingsPage;