import React, { createContext, useContext, useEffect, useState } from 'react';
import { Alert } from '@aws-amplify/ui-react';

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export function ErrorAlert() {
    const { error, setError } = useContext(ErrorContext);
  
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(null);
            }, 15000);
            return () => clearTimeout(timer); // This will clear the timer if the component is unmounted before the timer fires
        }
    }, [error, setError]); // This effect runs whenever the error changes
  
    if (!error) return null;
  
    return (
      <Alert variation="error" isDismissible={true}>
        {error}
      </Alert>
    );
}